// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import SessionTimeout from './SessionTimeout';
import { Box, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const OtaPage = () => {

    return (
        <>
            <Box p={8}>
                <SessionTimeout timeoutDuration={1800000} />
                Hello Moto
            </Box>
        </>
    );
}

export default OtaPage;
