// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Input,
    Switch,
    FormLabel,
    FormControl,
    Grid,
    Heading,
    Select,
    Textarea,
    Checkbox,
    Button,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    theme
} from '@chakra-ui/react';
import CustomCard from '../components/CustomCard';
import { BsCurrencyDollar } from 'react-icons/bs';
import PieChartComponent from '../components/PieChartComponent';
import BarChartComponent from '../components/BarChartComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { addCamera, getDeviceInfo, getImageInfo, getNetInfo, getNetworkInterfaceSettings, getTimeSettings, getVideoEncodeChannelMain, getVideoEncodeChannelSub, getVideoSettings, setImageInfo, setVideoEncodeChannelMain, setVideoSettings } from '../actions/cameraActions';
import SessionTimeout from './SessionTimeout';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import LineChartComponent from '../components/LineChartComponent';
import { getHealth } from '../actions/healthActions';

const CameraAnalysis = () => {

    const { deviceId } = useParams()
    const [healthData, setHealthData] = useState([]);

    const barChartOptions = {
        chart: {
            type: 'bar',
            height: '100%',
            background: 'transparent',
            stacked: false,
            // toolbar: {
            //   show: false
            // }
        },
        colors: [theme.colors.blue[500], theme.colors.green[500]],

        // title is sent separately...

        // title: {
        //   text: 'Monthly Sales Data Comparison',
        //   align: 'left',
        // },
        fill: {
            opacity: 1,
            type: 'solid'
        },
        grid: {
            strokeDashArray: 1,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30px',
            },
        }
    };
    const barChartTitle = 'Monthly Sales Data Comparison';
    const barChartSeries = [
        {
            name: 'Sales 2023',
            data: [65, 59, 80, 81, 56, 55, 65],
        }
        // ,
        // {
        //   name: 'Sales 2024',
        //   data: [75, 69, 70, 91, 66, 65],
        // },
    ];

    // line chart
    const lineChartOptions = {
        chart: {
            type: 'line',
            height: '100%',
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: true,
                tools: {
                    download: true,  // Include download option
                    zoom: false,     // Disable zoom
                    pan: false,      // Disable pan
                }
            },
            zoom: {
                enabled: false
            },
        },
        colors: [theme.colors.blue[500], theme.colors.green[500]],
        fill: {
            opacity: 1,
            type: 'solid'
        },
        grid: {
            strokeDashArray: 1,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            categories: healthData.CPUUsage ? healthData.CPUUsage.map(item => item.time) : [],
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            size: 5,
            colors: ['#fff'],
            strokeWidth: 2,
        },
        yaxis: {
            min: 0,  // Set minimum value for y-axis
            max: 100, // Set maximum value for y-axis (adjust as needed)
            title: {
                text: 'Sales',
            },
        },
    };

    // Line chart data (series)
    const lineChartSeries = [
        {
            name: 'Storage',
            data: healthData.SD_Card ? healthData.SD_Card.map(item => item.value) : [],  // Data points for the line chart
        },
        {
            name: 'CPU Usage',
            data: healthData.CPUUsage ? healthData.CPUUsage.map(item => item.value) : [],
        }
    ];


    /////////////////// SECOND LINE CHART ////////////////////

    const lineChartOptions2 = {
        chart: {
            type: 'line',
            height: '100%',
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: true,
                tools: {
                    download: true,  // Include download option
                    zoom: false,     // Disable zoom
                    pan: false,      // Disable pan
                }
            },
            zoom: {
                enabled: false
            },
        },
        colors: [theme.colors.blue[500], theme.colors.green[500]],
        fill: {
            opacity: 1,
            type: 'solid'
        },
        grid: {
            strokeDashArray: 1,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            categories: healthData.hourlyCPU ? healthData.hourlyCPU.map(item => item.time) : [],
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            size: 5,
            colors: ['#fff'],
            strokeWidth: 2,
        },
        yaxis: {
            min: 0,  // Set minimum value for y-axis
            max: 100, // Set maximum value for y-axis (adjust as needed)
            title: {
                text: 'Sales',
            },
        },
    };

    // Line chart data (series)
    const lineChartSeries2 = [
        {
            name: 'Storage',
            data: healthData.hourlySD_Card ? healthData.hourlySD_Card.map(item => item.value) : [],
        },
        {
            name: 'CPU Usage',
            data: healthData.hourlyCPU ? healthData.hourlyCPU.map(item => item.value) : [],
        }
    ];


    //////////////////// THIRD LINE CHART ////////////////////

    const lineChartOptions3 = {
        chart: {
            type: 'line',
            height: '100%',
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: true,
                tools: {
                    download: true,  // Include download option
                    zoom: false,     // Disable zoom
                    pan: false,      // Disable pan
                }
            },
            zoom: {
                enabled: false
            },
        },
        colors: [theme.colors.blue[500], theme.colors.green[500]],
        fill: {
            opacity: 1,
            type: 'solid'
        },
        grid: {
            strokeDashArray: 1,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            categories: healthData.p2pStatus ? healthData.p2pStatus.map(item => item.time) : [],
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            size: 5,
            colors: ['#fff'],
            strokeWidth: 2,
        },
        yaxis: {
            min: 0,  // Set minimum value for y-axis
            max: 1, // Set maximum value for y-axis (adjust as needed)
            title: {
                text: 'Sales',
            },
        },
    };

    // Line chart data (series)
    const lineChartSeries3 = [
        // {
        //     name: 'Storage',
        //     data: healthData.p2pStatus ? healthData.p2pStatus.map(item => item.value) : [],
        // },
        {
            name: 'P2P Status',
            data: healthData.p2pStatus
                ? healthData.p2pStatus.map(item => item.value === 'online' ? 1 : 0)
                : [],

        }
    ];


    const fetchData = async () => {
        try {
            const response = await getHealth(deviceId);
            // console.log("Server Info:", response);
            setHealthData(response.data);
        } catch (error) {
            console.error("Error fetching server info:", error);
        }
    };

    // const [cpuUsageValue, setCpuUsageValue] = useState('');
    // const cpuUsage = async () => {
    //     try {
    //         // console.log("CPU value:", healthData);
    //         healthData.CPU.map((item) => {
    //             setCpuUsageValue(item.value);
    //             console.log("CPU value:", item.value);
    //         });

    //     } catch (error) {
    //         console.error("Error fetching server info:", error);
    //     }
    // };

    useEffect(() => {
        fetchData();
        // cpuUsage();
    }, [deviceId]);

    return (
        <Box p={8}>
            <Grid
                width="100%"
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    xl: "2fr 2fr",
                    lg: "2fr 2fr",
                    md: "1fr 1fr",
                    sm: "1fr 1fr",
                }}
                gap={6}
                padding="2% 2%"
                height="100%"
            >
                {/* <Box height="100%" display="flex" flexDirection="column">
                    <Box flex="1">
                        <BarChartComponent
                            options={barChartOptions}
                            series={barChartSeries}
                            title={barChartTitle}
                        />
                    </Box>
                </Box> */}
                <Box height="100%" display="flex" flexDirection="column">
                    <Box flex="1">
                        <LineChartComponent
                            options={lineChartOptions}
                            series={lineChartSeries}
                            title="CPU & Storage Usage"
                        />
                    </Box>
                </Box>
                <Box height="100%" display="flex" flexDirection="column">
                    <Box flex="1">
                        <LineChartComponent
                            options={lineChartOptions2}
                            series={lineChartSeries2}
                            title="Hourly CPU & Storage Usage"
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid
                width="100%"
                gap={6}
                padding="2% 2%"
                height="100%"
            >
                <Box height="100%" display="flex" flexDirection="column">
                    <Box flex="1">
                        <LineChartComponent
                            options={lineChartOptions3}
                            series={lineChartSeries3}
                            title="P2P STATUS"
                        />
                    </Box>
                </Box>
            </Grid>

        </Box>
    );
}

export default CameraAnalysis;
