import axios from 'axios';

const baseURL = 'https://ems.ambicam.com:5000/api';
// const baseURL = 'http://localhost:5000/api';

const instance = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

instance.interceptors.response.use(
    response => {
        // If the response is successful, just return the response
        return response;
    },
    error => {
        // If the response has a status code of 401, redirect to the login page
        if (error.response && error.response.status === 401) {
            window.location.href = '/'; // Replace with your login route
        }
        // Otherwise, reject the promise with the error object
        return Promise.reject(error);
    }
);

export async function getVideoEncodeChannelMain(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/video-encode-channel-main', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getVideoEncodeChannelSub(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/video-encode-channel-sub', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}


export async function getVideoSettings(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/video-settings', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getImageInfo(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/image-info', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getNetworkInterfaceSettings(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/network-interface-settings', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getNetInfo(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/network-info', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getTimeSettings(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/time-settings', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function getDeviceInfo(deviceId) {
    try {
        const params = { deviceId: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/device-info', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

/////////////////// CameraList /////////////////////

export async function getP2pCameras(page, deviceId) {
    try {
        const params = { page: page, querySearch: deviceId };
        // const token = localStorage.getItem('token');

        // const response = await instance.get('/allcamera', {
        const response = await instance.get('/camera/get-p2p-cameras', {
            params: params,
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            //     // Add any other headers if needed
            // },
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export async function addP2pCamera(formData) {
    // const token = localStorage.getItem('token');
    // console.log('formData2',formData);
    try {
        const response = await instance.post('/camera/add-p2p-camera',
            formData,
            // , {
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         // Add any other headers if needed
            //     }
            // }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

//////////////////////////////// POST REQUESTS ///////////////////////////////////////

export async function setVideoEncodeChannelMain(bitRate, frameRate, codecType, resolution, bitRateType, deviceId) {
    // const token = localStorage.getItem('token');
    const params = { deviceId: deviceId };
    try {
        const response = await instance.post('/video-encode-channel-main',
            {
                codecType: codecType,
                resolution: resolution,
                bitRateControlType: bitRateType,
                constantBitRate: bitRate,
                frameRate: frameRate,
            },
            {
                params: params
            }
            // , {
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         // Add any other headers if needed
            //     }
            // }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function setVideoSettings(hue, saturation, brightness, contrast, flipEnabled, mirrorEnabled, deviceId) {
    // const token = localStorage.getItem('token');
    const params = { deviceId: deviceId };
    try {
        const response = await instance.post('/video-settings',
            {
                hueLevel: hue,
                saturationLevel: saturation,
                brightnessLevel: brightness,
                contrastLevel: contrast,
                flipEnabled: flipEnabled,
                mirrorEnabled: mirrorEnabled
            },
            {
                params: params
            }
            // , {
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         // Add any other headers if needed
            //     }
            // }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function setImageInfo(irCut, deviceId) {
    // const token = localStorage.getItem('token');
    const params = { deviceId: deviceId };
    try {
        const response = await instance.post('/image-info',
            {
                irCutMode: irCut,
            },
            {
                params: params
            }
            // , {
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         // Add any other headers if needed
            //     }
            // }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

/////////////////////// POST REQUESTS ///////////////////////////

export async function addCamera(deviceId) {
    // const token = localStorage.getItem('token');
    try {
        const response = await instance.post('/camera/add-camera',
            {
                deviceId: deviceId,
            }
            // , {
            //     headers: {
            //         'Authorization': `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         // Add any other headers if needed
            //     }
            // }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

////////////////////// MQTT ///////////////////////////

export async function fetchLatestMessage() {
    try {

        const response = await instance.get('/alert/latest-message');
        return response;

    } catch (error) {
        throw error;
    }
}