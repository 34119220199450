import React from 'react';
import { Box, VStack, Button, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, IconButton, HStack, Flex, Image, Heading } from '@chakra-ui/react';
import { HamburgerIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { RiLiveLine } from "react-icons/ri";
import { FaRegUserCircle } from 'react-icons/fa';
import { BsRecordCircle } from 'react-icons/bs';
import { RxDashboard } from 'react-icons/rx';
import logo from '../images/logo.png';

const Sidebar = ({ isCollapsed, toggleCollapse }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const location = useLocation();

  const getButtonProps = (path) => ({
    color: location.pathname === path ? 'white' : '#9DA4AE',
    bg: location.pathname === path ? 'rgba(255,255,255,0.1)' : 'transparent',
    fontSize: 'sm',
    _hover: {
      bg: 'rgba(255,255,255,0.1)',
      color: 'white',
    },
    iconColor: location.pathname === path ? '#2C5282' : '#9DA4AE', // Dark blue or default icon color
  });

  return (
    <>
      {/* Sidebar for larger screens */}
      <Box
        display={{ base: 'none', lg: 'block' }}
        width={isCollapsed ? "80px" : "280px"}
        background='#1C2536'
        height="100vh"
        padding="1rem"
        boxShadow="md"
        transition="width 0.2s"
        position="fixed"
        top="0"
        left="0"
        zIndex={99999}
      >
        <VStack spacing={4} align="stretch">
          <Link to="/dashboard" style={{ textDecoration: 'none', width: '100%' }}>
            <Button {...getButtonProps('/dashboard')} width='100%' alignItems='center' justifyContent='left'>
              <HStack spacing={isCollapsed ? 0 : 4}>
                <RxDashboard color={getButtonProps('/dashboard').iconColor} />
                {!isCollapsed && <span>Dashboard</span>}
              </HStack>
            </Button>
          </Link>
          <Link to="/deviceinfo" style={{ textDecoration: 'none' }}>
            <Button {...getButtonProps('deviceinfo')} width='100%' alignItems='center' justifyContent='left'>
              <HStack spacing={isCollapsed ? 0 : 4}>
                <BsRecordCircle color={getButtonProps('deviceinfo').iconColor} />
                {!isCollapsed && <span>deviceinfo</span>}
              </HStack>
            </Button>
          </Link>
          <Link to="/cameraList" style={{ textDecoration: 'none' }}>
            <Button {...getButtonProps('deviceinfo')} width='100%' alignItems='center' justifyContent='left'>
              <HStack spacing={isCollapsed ? 0 : 4}>
                <BsRecordCircle color={getButtonProps('deviceinfo').iconColor} />
                {!isCollapsed && <span>Camera List</span>}
              </HStack>
            </Button>
          </Link>
          <Link to="/ota" style={{ textDecoration: 'none' }}>
            <Button {...getButtonProps('deviceinfo')} width='100%' alignItems='center' justifyContent='left'>
              <HStack spacing={isCollapsed ? 0 : 4}>
                <BsRecordCircle color={getButtonProps('deviceinfo').iconColor} />
                {!isCollapsed && <span>OTA</span>}
              </HStack>
            </Button>
          </Link>
        </VStack>
      </Box>

      {/* Hamburger icon for mobile view */}
      <IconButton
        ref={btnRef}
        icon={<HamburgerIcon />}
        display={{ base: 'block', lg: 'none' }}
        onClick={onOpen}
        position="fixed"
        top="1rem"
        left="1rem"
        zIndex={11}
      />

      {/* Drawer for mobile view */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <Button colorScheme="teal" variant="ghost" onClick={onClose} fontSize="sm">
                  Dashboard
                </Button>
              </Link>
              <Link to="/deviceinfo" style={{ textDecoration: 'none' }}>
                <Button colorScheme="teal" variant="ghost" onClick={onClose} fontSize="sm">
                  Deviceinfo
                </Button>
              </Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Sidebar;
