// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Input,
    Switch,
    FormLabel,
    FormControl,
    Grid,
    Heading,
    Select,
    Textarea,
    Checkbox,
    Button,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text
} from '@chakra-ui/react';
import CustomCard from '../components/CustomCard';
import { BsCurrencyDollar } from 'react-icons/bs';
import PieChartComponent from '../components/PieChartComponent';
import { useNavigate } from 'react-router-dom';
import { addCamera, getDeviceInfo, getImageInfo, getNetInfo, getNetworkInterfaceSettings, getTimeSettings, getVideoEncodeChannelMain, getVideoEncodeChannelSub, getVideoSettings, setImageInfo, setVideoEncodeChannelMain, setVideoSettings } from '../actions/cameraActions';
import SessionTimeout from './SessionTimeout';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

const Deviceinfo = () => {
    const [subscribedTo, setSubscribedTo] = useState('');
    const [status, setStatus] = useState('');
    const [rtmpUrl, setRtmpUrl] = useState('');
    const [mqttUrl, setMqttUrl] = useState('');
    const [url, setUrl] = useState('');
    const [fwUpdate, setFwUpdate] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [serverName, setServerName] = useState('');
    const [enableStream, setEnableStream] = useState(false);
    const [enableAudio, setEnableAudio] = useState(false);
    const [telnet, setTelnet] = useState(false);
    const [hd, setHd] = useState(false);
    const [dst, setDst] = useState(false);
    const [autoUpdate, setAutoUpdate] = useState(false);
    const [onvifSetTZ, setOnvifSetTZ] = useState(false);
    const [enableDateTime, setEnableDateTime] = useState(false);
    const [interval, setInterval] = useState(1);

    const [bitRate, setBitRate] = useState('');
    const [frameRate, setFrameRate] = useState('');
    const [codecType, setCodecType] = useState('');
    const [resolution, setResolution] = useState('');
    const [bitRateType, setBitRateType] = useState('');
    // const [quality, setQuality] = useState('1');
    const [hue, setHue] = useState();
    const [saturation, setSaturation] = useState();
    const [brightness, setBrightness] = useState();
    const [contrast, setContrast] = useState();
    const [flipEnabled, setFlipEnabled] = useState(false);
    const [mirrorEnabled, setMirrorEnabled] = useState(false);
    const [irCutMode, setIrCutMode] = useState('');
    // 
    const [ip, setIp] = useState('');
    const [interfaceName, setInterfaceName] = useState('');
    const [imei, setImei] = useState('');
    const [signal, setSignal] = useState(0);
    // 
    const [localtime, setLocaltime] = useState('');
    const [ntpServer, setNtpServer] = useState('');
    const [timezone, setTimeZone] = useState('');
    // 
    const [deviceName, setDeviceName] = useState('');
    const [extSN2, setExtSN2] = useState('');
    const [firmwareReleaseDate, setFirmwareReleaseDate] = useState('');
    const [firmwareVersion, setFirmwareVersion] = useState('');
    const [hardwareVersion, setHardwareVersion] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [manufacturer, setManufacturer] = useState('');

    const [deviceId, setDeviceId] = useState('');
    const navigate = useNavigate();

    // const [configValue, setConfigValue] = useState('');

    // Function to handle input change
    const handleInputChange = (event) => {
        setDeviceId(event.target.value); // Update the input value in state
    };

    const setVideoConfigurations = async () => {
        try {
            const response = await setVideoEncodeChannelMain(bitRate, frameRate, codecType, resolution, bitRateType, deviceId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleVideoSettings = async () => {
        try {
            const response = await setVideoSettings(hue, saturation, brightness, contrast, flipEnabled, mirrorEnabled, deviceId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleImageInfo = async () => {
        try {
            const response = await setImageInfo(irCutMode, deviceId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddCamera = async () => {
        try {
            // alert for are you sure you want to add this camera? with cancel buttton
            if (window.confirm('Are you sure you want to add this camera?')) {
                // If the user confirms, proceed with the API call
                const response = await addCamera(deviceId);
                toast.success('Camera added successfully!');
            } else {
                // If the user cancels, do nothing
                return;
            }
            // const response = await addCamera(deviceId);
            // console.log('addCameraResponse',response);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error adding camera: ' + error.response.data.message);
        }
    };

    const getConfig = async () => {
        try {
            const response = await getVideoEncodeChannelMain(deviceId);
            setFrameRate(response?.data?.frameRate || ''); // Optional chaining
            setBitRate(response?.data?.constantBitRate || ''); // Optional chaining
            setCodecType(response?.data?.codecType || ''); // Optional chaining
            setResolution(response?.data?.resolution || ''); // Optional chaining
            setBitRateType(response?.data?.bitRateControlType || ''); // Optional chaining

            const response3 = await getVideoSettings(deviceId);
            setHue(response3?.data?.hueLevel || 0); // Optional chaining
            setSaturation(response3?.data?.saturationLevel || 0); // Optional chaining
            setBrightness(response3?.data?.brightnessLevel || 0); // Optional chaining
            setContrast(response3?.data?.contrastLevel || 0); // Optional chaining
            setFlipEnabled(response3?.data?.flipEnabled || false); // Optional chaining
            setMirrorEnabled(response3?.data?.mirrorEnabled || false); // Optional chaining

            const response4 = await getImageInfo(deviceId);
            setIrCutMode(response4?.data?.irCutFilter?.irCutMode || ''); // Optional chaining

            const response5 = await getNetworkInterfaceSettings(deviceId);
            setIp(response5?.data?.lan?.staticIP || ''); // Optional chaining
            setInterfaceName(response5?.data?.interfaceName || ''); // Optional chaining

            const response6 = await getNetInfo(deviceId);
            setImei(response6?.data?.IMEI || ''); // Optional chaining
            setSignal(response6?.data?.Signal || ''); // Optional chaining

            const response7 = await getTimeSettings(deviceId);
            setLocaltime(response7?.data?.localTime || ''); // Optional chaining
            setNtpServer(response7?.data?.ntp?.ntpServerDomain || ''); // Optional chaining
            setTimeZone(response7?.data?.timeZone || ''); // Optional chaining

            const response8 = await getDeviceInfo(deviceId);
            setDeviceName(response8?.data?.deviceName || ''); // Optional chaining
            setExtSN2(response8?.data?.extSN2 || ''); // Optional chaining
            setFirmwareReleaseDate(response8?.data?.firmwareReleaseDate || ''); // Optional chaining
            setFirmwareVersion(response8?.data?.firmwareVersion || ''); // Optional chaining
            setHardwareVersion(response8?.data?.hardwareVersion || ''); // Optional chaining
            setMacAddress(response8?.data?.macAddress || ''); // Optional chaining
            setManufacturer(response8?.data?.manufacturer || ''); // Optional chaining
        } catch (error) {
            console.error("Error fetching device info:", error); // Log error to console
        }

    };

    return (
        <Box p={8}>
            <SessionTimeout timeoutDuration={1800000} />
            <Flex
                as="nav"
                align="center"
                justify="flex-end"
                w="100%" // Full width of the viewport or container
                p={4} // Padding around the content
                bg="transparent" // Makes background transparent
            >
                <Box w="50%"> {/* Box taking up half of the screen width */}
                    <Flex justify="space-between" w="100%"> {/* Flex container to space out children */}
                        <Input
                            flex={1}
                            value={deviceId}
                            onChange={handleInputChange} // Capture input change
                            placeholder="Enter Config"
                        /> {/* Input takes maximum width available */}
                        <Button onClick={getConfig} colorScheme="blue" ml={4}>Get Configs</Button> {/* Margin left for spacing */}
                        <Button onClick={handleAddCamera} colorScheme="blue" ml={4}>Add Camera</Button>
                    </Flex>
                </Box>
            </Flex>
            <ToastContainer />

            {/* Camera Details */}
            {/* <Heading size="md" mb={4}>Camera Details</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>Subscribed to</FormLabel>
                    <Input
                        placeholder="Subscribed to"
                        value={subscribedTo}
                        onChange={(e) => setSubscribedTo(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Input
                        placeholder="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    />
                </FormControl>
            </Grid> */}

            {/* Stream Configurations */}
            {/* <Heading size="md" mt={8} mb={4}>Stream Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>Enable</FormLabel>
                    <Switch isChecked={enableStream} onChange={() => setEnableStream(!enableStream)} />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>Audio</FormLabel>
                    <Switch isChecked={enableAudio} onChange={() => setEnableAudio(!enableAudio)} />
                </FormControl>
            </Grid>

            <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={4}>
                <FormControl>
                    <FormLabel>RTMP URL</FormLabel>
                    <Input
                        placeholder="RTMP URL"
                        value={rtmpUrl}
                        onChange={(e) => setRtmpUrl(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>MQTT URL</FormLabel>
                    <Input
                        placeholder="MQTT URL"
                        value={mqttUrl}
                        onChange={(e) => setMqttUrl(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>URL</FormLabel>
                    <Input
                        placeholder="URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </FormControl>
            </Grid>

            <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={4}>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>Telnet</FormLabel>
                    <Switch isChecked={telnet} onChange={() => setTelnet(!telnet)} />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>HD</FormLabel>
                    <Switch isChecked={hd} onChange={() => setHd(!hd)} />
                </FormControl>
                <FormControl>
                    <FormLabel>FW Update</FormLabel>
                    <Input
                        placeholder="FW update"
                        value={fwUpdate}
                        onChange={(e) => setFwUpdate(e.target.value)}
                    />
                </FormControl>
            </Grid> */}

            {/* Date And Time Configurations */}
            <Heading size="md" mt={8} mb={4}>Date And Time Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>LocalTime</FormLabel>
                    <Input disabled value={localtime} placeholder="IP Address" />
                </FormControl>
                <FormControl>
                    <FormLabel>NTP Server Domain</FormLabel>
                    <Input disabled value={ntpServer} placeholder="Interface Name" />
                </FormControl>
                <FormControl>
                    <FormLabel>Timezone</FormLabel>
                    <Input disabled value={timezone} placeholder="Interface Name" />
                </FormControl>
            </Grid>

            <Heading size="md" mt={10} mb={4}>Video Configurations</Heading>
            <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                {/* Video configuration fields */}
                <FormControl>
                    <FormLabel>Bit Rate</FormLabel>
                    <Input value={bitRate} onChange={(e) => setBitRate(e.target.value)} placeholder="Bit Rate" />
                </FormControl>
                <FormControl>
                    <FormLabel>FPS</FormLabel>
                    <Input value={frameRate} onChange={(e) => setFrameRate(e.target.value)} placeholder="FPS" />
                </FormControl>
                <FormControl>
                    <FormLabel>Profile</FormLabel>
                    <Select value={codecType} onChange={(e) => setCodecType(e.target.value)} placeholder='Codec Type'>
                        <option value="H.264">H.264</option>
                        <option value="H.265">H.265</option>
                        <option value="H.264+">H.264+</option>
                        <option value="H.265+">H.265+</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Bit Rate Type</FormLabel>
                    <Select value={bitRateType} onChange={(e) => setBitRateType(e.target.value)} placeholder="Select type">
                        <option>CBR</option>
                        <option>VBR</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Resolution</FormLabel>
                    <Select value={resolution} onChange={(e) => setResolution(e.target.value)} placeholder="Select resolution">
                        <option value="1280x720">1280x720</option>
                        {/* Add other resolutions */}
                    </Select>
                </FormControl>
                {/* Button for saving settings */}
                <Box alignSelf="flex-end">
                    <Button colorScheme="blue" onClick={setVideoConfigurations}>
                        Save Configuration
                    </Button>
                </Box>
            </Grid>


            <Heading size="md" mt={10} mb={4}>Display Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel htmlFor="hue-slider">Hue</FormLabel>
                    <Slider
                        id="hue-slider"
                        value={hue}
                        onChange={(value) => setHue(value)}
                        min={0}
                        max={100}
                        aria-label="hue-slider"
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Box pt={2}>
                        <Text fontSize="sm">Current Hue: {hue}</Text>
                    </Box>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="hue-slider">Saturation</FormLabel>
                    <Slider
                        id="hue-slider"
                        value={saturation}
                        onChange={(value) => setSaturation(value)}
                        min={0}
                        max={100}
                        aria-label="hue-slider"
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Box pt={2}>
                        <Text fontSize="sm">Current Saturation: {saturation}</Text>
                    </Box>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="hue-slider">Brightness</FormLabel>
                    <Slider
                        id="hue-slider"
                        value={brightness}
                        onChange={(value) => setBrightness(value)}
                        min={0}
                        max={100}
                        aria-label="hue-slider"
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Box pt={2}>
                        <Text fontSize="sm">Current Brightness: {brightness}</Text>
                    </Box>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="hue-slider">Contrast</FormLabel>
                    <Slider
                        id="hue-slider"
                        value={contrast}
                        onChange={(value) => setContrast(value)}
                        min={0}
                        max={100}
                        aria-label="hue-slider"
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Box pt={2}>
                        <Text fontSize="sm">Current Contrast: {contrast}</Text>
                    </Box>
                </FormControl>
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="ssl-switch" mb="0">
                        Flip
                    </FormLabel>
                    <Switch isChecked={flipEnabled} onChange={(e) => setFlipEnabled(e.target.checked)} id="ssl-switch" />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="ssl-switch" mb="0">
                        Mirror
                    </FormLabel>
                    <Switch isChecked={mirrorEnabled} onChange={(e) => setMirrorEnabled(e.target.checked)} id="ssl-switch" />
                </FormControl>
                <Box alignSelf="flex-end">
                    <Button colorScheme="blue" onClick={handleVideoSettings}>
                        Set Video Settings
                    </Button>
                </Box>
                {/* <FormControl>
                    <FormLabel>IR Cut</FormLabel>
                    <Select placeholder="Select mode">
                        <option>Day & Night</option>
                        <option>Day Only</option>
                        <option>Night Only</option>
                    </Select>
                </FormControl> */}
            </Grid>

            <Heading size="md" mt={10} mb={4}>Image Configurations</Heading>
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>IRCut</FormLabel>
                    <Select value={irCutMode} onChange={(e) => setIrCutMode(e.target.value)} placeholder='modes'>
                        <option value='auto'>IrLedMode</option>
                        <option value='light'>Light Mode</option>
                        <option value='smart'>Smart Mode</option>
                        <option value='daylight'>Daylight Mode</option>
                        <option value='night'>Night Mode</option>
                    </Select>
                </FormControl>
                <Box alignSelf="flex-end">
                    <Button colorScheme="blue" onClick={handleImageInfo}>
                        Set IrCut
                    </Button>
                </Box>
            </Grid>

            {/* <Heading size="md" mt={10} mb={4}>Image Configurations</Heading>
            <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>Flip</FormLabel>
                    <Checkbox>Enable</Checkbox>
                </FormControl>
                <FormControl>
                    <FormLabel>Mirror</FormLabel>
                    <Checkbox>Enable</Checkbox>
                </FormControl>
                <FormControl>
                    <FormLabel>WDR</FormLabel>
                    <Select placeholder="Off">
                        <option>On</option>
                        <option>Off</option>
                    </Select>
                </FormControl>
            </Grid> */}

            <Heading size="md" mt={10} mb={4}>PTZ Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>Left</FormLabel>
                    <Input placeholder="Position" />
                </FormControl>
                {/* Repeat for other PTZ directions */}
            </Grid>

            <Heading size="md" mt={10} mb={4}>Device Info Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>Device Name</FormLabel>
                    <Input disabled value={deviceName} placeholder="Device Name" />
                </FormControl>
                <FormControl>
                    <FormLabel>Ext SN2</FormLabel>
                    <Input disabled value={extSN2} placeholder="Ext SN2" />
                </FormControl>
                <FormControl>
                    <FormLabel>Firmware Release Date</FormLabel>
                    <Input disabled value={firmwareReleaseDate} placeholder="Date" />
                </FormControl>
                <FormControl>
                    <FormLabel>Firmware Version</FormLabel>
                    <Input disabled value={firmwareVersion} placeholder="Firmware Version" />
                </FormControl>
                <FormControl>
                    <FormLabel>Hardware Version</FormLabel>
                    <Input disabled value={hardwareVersion} placeholder="Hardware Version" />
                </FormControl>
                <FormControl>
                    <FormLabel>Mac Address</FormLabel>
                    <Input disabled value={macAddress} placeholder="Mac Address" />
                </FormControl>
                <FormControl>
                    <FormLabel>Manufacturer</FormLabel>
                    <Input disabled value={manufacturer} placeholder="Manufacturer" />
                </FormControl>
                {/* Additional device info fields */}
            </Grid>

            <Heading size="md" mt={10} mb={4}>Network Info Configurations</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl>
                    <FormLabel>IP</FormLabel>
                    <Input disabled value={ip} placeholder="IP Address" />
                </FormControl>
                <FormControl>
                    <FormLabel>Interface Name</FormLabel>
                    <Input disabled value={interfaceName} placeholder="Interface Name" />
                </FormControl>
                <FormControl>
                    <FormLabel>IMEI</FormLabel>
                    <Input disabled value={imei} placeholder="Interface Name" />
                </FormControl>
                <FormControl>
                    <FormLabel>Signal</FormLabel>
                    <Input disabled value={signal} placeholder="Interface Name" />
                </FormControl>
                {/* Additional network info fields */}
            </Grid>
        </Box>
    );
}

export default Deviceinfo;
